<template>
  <div class="solutionList-content">
    <h2>行业概述</h2>
    <p>
      面向药品供应链计划管理层和药品生产车间作业执行层，充分满足制药企业业务快速发展对生产管理水平的高要求，尤其是供应链计划管理。通过APS系统进行订单的自动排产、准确评估设备产能、制定精细的物料需求计划、检验计划，进行快速交期答复，搭建统一的产销平台，从而帮助药企提升计划管理水平。 为了能很好的解决计划中的问题，并结合公司数字化建设，引入APS高级计划与排程系统，以此来实现生产排程过程的自动化、透明化和可视化，排产经验规则化，产能标准化，提高计划响应能力及物料齐套率，促进计划执行力，最终提升供应链产销协同能力，提高客户满意度。
    </p>
    <h2>解决案例</h2>
    <div>
      <ul class="imgList">
        <li v-for="item in imgList" :key="item.id">
          <img alt="" :src="item.src">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  mounted() {

  },
  data() {
    return {
      imgList: [
        {
          id: 1,
          src: require("../../../assets/image/zhiyao1.png")
        },{
          id: 2,
          src: require("../../../assets/image/zhiyao2.png")
        },{
          id: 3,
          src: require("../../../assets/image/zhiyao3.png")
        },{
          id: 4,
          src: require("../../../assets/image/zhiyao4.png")
        },
      ]
    };
  },

  methods: {}


};
</script>
<style lang="less">
.solutionList-content {
  .imgList {
    display: flex;
    flex-direction: column;
    li {
      //flex-grow: 1;
      margin-bottom: 20px;
      img {
        width: 80%;

      }
    }
    li:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
