<template>
  <div class="solutionList-content">
    <h2>行业概述</h2>
    <p>
      FMCS（Factory Management Control System）厂务管理控制系统是一种专为制造业企业设计的集成管理解决方案。该系统旨在提升企业的生产效率、降低生产成本、增强生产安全性，并提供全面的制造过程管理和可视化监控。下面是对FMCS厂务管理控制系统解决方案的描述：
      <br>
      1. 生产计划与排程：FMCS系统可以帮助企业进行生产计划和排程管理。它基于订单需求、设备能力和人力资源等要素，自动生成生产计划，并进行排程优化。系统可以实时监控生产进度和资源利用情况，确保生产计划的准确执行，提高生产效率和交付准时性。
      <br>
      2. 生产过程管理：FMCS系统可以对生产过程进行全面管理。它集成了制造工艺流程、质量控制、工艺参数监测等功能模块，实现对生产过程的实时监控和控制。系统可以监测和记录关键工艺参数，并根据预设规则进行自动调整和报警，确保产品质量稳定和一致性。
      <br>
      3. 设备管理与维护：FMCS系统可以进行设备管理和维护计划的制定与执行。它可以收集和分析设备运行数据，进行设备状态监测和预测性维护。系统可以提供设备维护计划和保养记录，帮助企业合理安排设备维修和保养，降低设备故障率，提高设备可靠性和生产效率。
      <br>
      4. 质量管理与追溯：FMCS系统支持质量管理和产品追溯。它可以进行质量检验和检测数据的采集与分析，实现产品质量可追溯。系统可以进行不良品的追溯和溯源，帮助企业追踪和处理质量问题，并进行质量改进和预防措施的分析和管理。
      <br>
      5. 实时监控与可视化：FMCS系统可以实现生产过程的实时监控和可视化展示。通过仪表盘、报表和图形化界面，系统可以直观地展示生产进度、资源利用、质量指标等重要信息。它还可以提供实时报警和预警功能，警示生产异常和潜在问题，帮助企业及时采取措施，确保生产的稳定和安全。
      <br>
      通过实施FMCS厂务管理控制系统解决方案，制造业企业可以实现生产过程的全面监控与优化，提高生产效率、产品质量和资源利用效率，增强竞争力和可持续发展能力。在选择和实施解决方案时，企业需要根据自身的需求和情况进行定制和配置，并与专业的解决方案提供商合作。
    </p>
    <h2>解决案例</h2>
    <div>
      <ul class="imgList">
        <li v-for="item in imgList" :key="item.id">
          <img alt="" :src="item.src">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  mounted() {

  },
  data() {
    return {
      imgList: [
        {
          id: 1,
          src: require("../../../assets/image/FMCS1.png")
        },{
          id: 2,
          src: require("../../../assets/image/FMCS2.png")
        },{
          id: 3,
          src: require("../../../assets/image/FMCS3.png")
        },{
          id: 4,
          src: require("../../../assets/image/FMCS4.png")
        },
      ]
    };
  },

  methods: {}


};
</script>
<style lang="less">
.solutionList-content {
  .imgList {
    display: flex;
    flex-direction: column;
    li {
      //flex-grow: 1;
      margin-bottom: 20px;
      img {
        width: 80%;

      }
    }
    li:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
