<template>
  <div class="solutionList-content">
    <h2>行业概述</h2>
    <p>
      谷物饲料行业的控制系统解决方案主要针对谷物加工、饲料生产及其相关环节的自动化控制、监测和管理。以下是对谷物饲料行业控制系统解决方案的一个概括描述：
      <br>
      1. 原料处理和配料控制：谷物饲料生产需要对各种原料进行处理和配料，以满足动物的营养需求。控制系统可以实现原料的计量、混合和输送控制，通过自动化的方式确保原料的准确配比和稳定加工过程。系统可以根据设定的配方参数，自动进行原料投料和混合控制，提高产品质量的一致性。
      <br>
      2. 生产工艺控制：谷物饲料行业涉及多个生产工艺环节，如研磨、混合、造粒、喷涂等。控制系统可以监测和控制生产工艺中的温度、湿度、压力、流量等重要参数，确保工艺过程的稳定性和产品质量的可控性。通过自动化控制，可以实现工艺参数的调节和优化，提高生产效率和产品一致性。
      <br>
      3. 设备管理与维护：谷物饲料行业的生产设备较为复杂，包括破碎机、搅拌机、造粒机等。控制系统可以实现对设备状态的监测和远程控制，及时发现设备故障并采取措施进行维护和修复。系统可以记录设备工作时间、维护记录和维修历史等信息，提供设备管理和预防性维护的支持，降低设备故障率和生产停工时间。
      <br>
      4. 数据管理与分析：在谷物饲料行业，生产过程中产生大量的数据，包括生产参数、设备运行状态、原料供应和产品质量等。控制系统可以对这些数据进行采集、存储和分析，提供数据报表、趋势分析和预测模型等功能。通过数据管理与分析，企业可以了解生产过程中的关键指标和变化趋势，做出决策优化和质量控制。
      <br>
      5. 安全管理与环境监测：谷物饲料行业需要关注安全生产和环境保护。控制系统可以集成安全监测设备和环境监测设备，监测工作区域的安全状况和环境参数，包括温度、湿度、气体浓度等。通过及时报警和自动控制，确保工作场所的安全性和环境的合规性。
      <br>
      这些控制系统解决方案可以根据谷物饲料企业的具体需求进行定制和配置，以提高生产效率、产品质量和安全性，同时降低运营成本和风险。控制系统的选型和实施需要综合考虑企业规模、生产工艺、数据管理需求等因素，并与专业的控制系统提供商进行合作。
    </p>
    <h2>解决案例</h2>
    <div>
      <ul class="imgList">
        <li v-for="item in imgList" :key="item.id">
          <img alt="" :src="item.src">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  mounted() {

  },
  data() {
    return {
      imgList: [
        {
          id: 1,
          src: require("../../../assets/image/guwu1.png")
        },{
          id: 2,
          src: require("../../../assets/image/guwu2.png")
        }
      ]
    };
  },

  methods: {}


};
</script>
<style lang="less">
.solutionList-content {
  .imgList {
    display: flex;
    flex-direction: column;
    li {
      //flex-grow: 1;
      margin-bottom: 20px;
      img {
        width: 80%;

      }
    }
    li:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
