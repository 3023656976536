<template>
  <div class="solutionList-content">
    <h2>行业概述</h2>
    <p>
      环保行业的控制系统解决方案旨在实现对环境污染的控制、监测和管理。以下是对环保行业控制系统解决方案的一个概括描述：
      <br>
      1. 污水处理控制：环保行业需要处理工业污水、废水等污染物，控制系统可以实现对污水处理工艺的自动化控制。系统可以监测和控制污水处理过程中的各项参数，如流量、浊度、pH值、溶解氧等，以确保处理工艺的稳定性和效果。通过自动化的控制和调节，可以提高污水处理效率和减少排放的污染物。
      <br>
      2. 大气污染控制：环保行业也需要对大气污染进行控制，控制系统可以集成空气质量监测设备和污染源排放控制设备。系统可以监测环境空气中的污染物浓度，对污染源进行实时控制和调节，以降低大气污染的程度。通过智能化控制系统，可以实现自动化的污染源排放管理和监测，确保大气环境的质量和合规性。
      <br>
      3. 噪声和振动控制：环保行业常涉及到噪声和振动的控制，特别是在工厂、加工设备等场所。控制系统可以监测和控制工作场所的噪声和振动水平，通过声学传感器、振动传感器等设备实时监测，采取控制措施，包括噪声屏蔽、振动减少等，以减少对周边环境和员工的干扰和危害。
      <br>
      4. 能源管理与节约：环保行业也需要关注能源的管理与节约，控制系统可以实现能源消耗的监测和控制。系统可以实时采集和分析能源消耗数据，并提供能源效率评估和优化建议。通过控制系统的帮助，企业可以降低能源消耗、提高能源利用效率，从而实现环境保护和经济效益的双重目标。
      <br>
      5. 数据管理与报告：环保行业产生的大量数据需要进行管理和分析，包括监测数据、排放数据、能源消耗数据等。控制系统可以采集、存储和分析这些数据，并生成报告和趋势分析，帮助企业了解环保指标、合规性，并支持决策优化和环境管理工作。
      <br>
      这些控制系统解决方案可以根据环保企业的具体需求进行定制和配置，以满足不同的环境治理要求。控制系统的选型和实施需要综合考虑企业的规模、污染物特性、数据管理需求等因素，并与专业的控制系统提供商进行合作。
    </p>
    <h2>解决案例</h2>
    <div>
      <ul class="imgList">
        <li v-for="item in imgList" :key="item.id">
          <img alt="" :src="item.src">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  mounted() {

  },
  data() {
    return {
      imgList: [
        {
          id: 1,
          src: require("../../../assets/image/huanbao1.png")
        },{
          id: 2,
          src: require("../../../assets/image/huanbao2.png")
        },{
          id: 3,
          src: require("../../../assets/image/huanbao3.png")
        },{
          id: 4,
          src: require("../../../assets/image/huanbao4.png")
        },
      ]
    };
  },

  methods: {}


};
</script>
<style lang="less">
.solutionList-content {
  .imgList {
    display: flex;
    flex-direction: column;
    li {
      //flex-grow: 1;
      margin-bottom: 20px;
      img {
        width: 80%;

      }
    }
    li:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
