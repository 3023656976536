<template>
  <div class="solutionList-content">
    <h2>行业概述</h2>
    <p>
      调味品行业生产管理MES系统与其他行业相比，有一些特殊性和重点。

      首先，调味品行业的生产过程中，存在多个工序和原料配比的复杂性。不同的产品需要不同的原料和工艺，而每个工序的参数和环境条件也需要严格控制，以确保产品质量的稳定性和一致性。

      其次，调味品行业的生产过程中，需要考虑到产品的口感和口味等方面的因素。这就要求MES系统需要支持对生产过程中各个关键节点进行精细化控制，并能够根据口感和口味等要求进行灵活调整。

      此外，调味品行业在生产过程中需要严格控制原材料和成品的库存，并及时跟踪和处理过期、损耗、损坏等情况，以保证库存的及时更新和产品质量的稳定性。

      针对以上特殊性和重点，MES系统需要具备以下功能和特点：

      多层级生产过程控制：支持多个工序和关键节点的实时监测和控制，确保产品质量的稳定性和一致性。

      精细化参数控制：支持对生产过程中各个关键节点的参数进行精细化控制，满足产品口感和口味等方面的要求。

      库存管理功能：支持原材料和成品的库存管理，及时跟踪和处理过期、损耗、损坏等情况，确保库存的及时更新和产品质量的稳定性。

      数据分析和预测功能：支持对生产数据的实时分析和预测，帮助企业及时发现问题和瓶颈，提高生产效率和产品质量。

      智能化优化功能：通过对生产数据的分析和处理，自动优化生产计划和流程，提高生产效率和产品质量。
    </p>
    <h2>解决案例</h2>
    <div>
      <ul class="imgList">
        <li v-for="item in imgList" :key="item.id">
          <img alt="" :src="item.src">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  mounted() {

  },
  data() {
    return {
      imgList: [
        {
          id: 1,
          src: require("../../../assets/image/xiangjin1.png")
        },{
          id: 2,
          src: require("../../../assets/image/xiangjin2.png")
        },{
          id: 3,
          src: require("../../../assets/image/xiangjin3.png")
        },{
          id: 4,
          src: require("../../../assets/image/xiangjin4.png")
        },
      ]
    };
  },

  methods: {}


};
</script>
<style lang="less">
.solutionList-content {
  .imgList {
    display: flex;
    flex-direction: column;
    li {
      //flex-grow: 1;
      margin-bottom: 20px;
      img {
        width: 80%;

      }
    }
    li:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
