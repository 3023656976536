<template>
  <div class="solutionList-content">
    <h2>行业概述</h2>
    <p>
      可视化平台打造的智慧工厂注重实现数据统一化，集成工业智能化管理系统，打破了数据孤岛化现状，实现了设备可视化，打造智能工厂管控一体化平台和工业智能化管理平台。有效提高工厂作业的综合监管能力、降低企业运营成本，实现管理精细化、决策科学化和服务高效化，可广泛应用于生产决策、日常监控、作业调度、数据分析、成果汇报等多种场景
    </p>
    <h2>解决案例</h2>
    <div>
      <ul class="imgList">
        <li v-for="item in imgList" :key="item.id">
          <img alt="" :src="item.src">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  mounted() {

  },
  data() {
    return {
      imgList: [
        {
          id: 1,
          src: require("../../../assets/image/brew1.png")
        },{
          id: 2,
          src: require("../../../assets/image/brew2.png")
        },{
          id: 3,
          src: require("../../../assets/image/brew3.png")
        },{
          id: 4,
          src: require("../../../assets/image/brew4.png")
        },
      ]
    };
  },

  methods: {}


};
</script>
<style lang="less">
  .solutionList-content {
    .imgList {
      display: flex;
      flex-direction: column;
      li {
        //flex-grow: 1;
        margin-bottom: 20px;
        img {
          width: 80%;

        }
      }
      li:last-child {
        margin-bottom: 0px;
      }
    }
  }
</style>
