<template>
  <div class="solutionList-content">
    <h2>行业概述</h2>
    <p>
      粮油行业的控制系统解决方案主要涉及粮食和食用油生产过程中的自动化控制、监测和数据管理等方面。以下是对粮油行业控制系统解决方案的一个概括描述：
      <br>
      1. 生产过程自动化控制：粮油行业的生产过程包括粮食的加工、储存和食用油的提取等环节。通过自动化控制系统，可以实现对生产设备（如清理机、研磨机、脱水机、压榨机等）的自动控制和调节，提高生产效率和质量稳定性。自动化控制系统可以根据设定的参数，在生产过程中实时监测和控制温度、压力、流量等关键指标，以确保生产过程的稳定性和一致性。
      <br>
      2. 远程监测与管理：粮油行业的生产设备分布在不同的地理位置，通过远程监测与管理系统，可以实时监测设备状态和生产过程，并对设备进行远程操作和参数调整。远程监测与管理系统可以通过实时数据采集和传输，提供设备故障预警、生产指标分析和报告等功能，帮助企业及时发现问题并采取措施，提高生产效率和安全性。
      <br>
      3. 数据管理与分析：粮油行业的生产过程中产生大量的数据，包括生产参数、设备运行状态、原材料质量等。通过数据管理与分析系统，可以对这些数据进行采集、存储和分析。数据管理与分析系统可以提供数据可视化、报表生成、趋势分析等功能，帮助企业了解生产过程中的关键指标和变化趋势，并做出决策优化和质量控制。
      <br>
      4. 质量追溯与合规性管理：粮油行业对产品质量和合规性有着严格的要求。控制系统可以通过质量追溯与合规性管理，对原材料的采购、生产过程中的记录和检测数据以及成品的库存和销售等进行全程追踪和管理。质量追溯与合规性管理系统可以确保产品质量可追溯，同时满足相关的法规和标准要求。
      <br>
      5. 安全管理与环境监测：粮油行业在生产过程中需要关注安全管理和环境保护。控制系统可以集成安全监测设备和环境监测设备，实时监测工作区域的安全状况和环境参数，包括温度、湿度、气体浓度等。通过及时报警和自动控制，确保工作场所的安全性和环境的合规性。
      <br>
      这些控制系统解决方案可以根据粮油企业的具体需求进行定制和配置，以提高生产效率、质量稳定性和合规性，同时降低运营成本和风险。控制系统的选型和实施需要综合考虑企业规模、生产工艺、数据管理需求等因素，并与专业的控制系统提供商进行合作。
    </p>
    <h2>解决案例</h2>
    <div>
      <ul class="imgList">
        <li v-for="item in imgList" :key="item.id">
          <img alt="" :src="item.src">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  mounted() {

  },
  data() {
    return {
      imgList: [
        {
          id: 1,
          src: require("../../../assets/image/egrain1.png")
        },{
          id: 2,
          src: require("../../../assets/image/egrain2.png")
        }
      ]
    };
  },

  methods: {}


};
</script>
<style lang="less">
.solutionList-content {
  .imgList {
    display: flex;
    flex-direction: column;
    li {
      //flex-grow: 1;
      margin-bottom: 20px;
      img {
        width: 80%;

      }
    }
    li:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
