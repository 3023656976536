<template>
  <div class="solutionList-content">
    <h2>行业概述</h2>
    <p>
      洁净生产BMS（Building Management System）/EMS（Energy Management System）控制系统解决方案是一种针对洁净生产环境设计的集中监控和管理系统。它旨在帮助企业实现节能减排、提高生产效率和优化资源利用的目标。下面是对洁净生产BMS/EMS控制系统解决方案的描述：
      <br>
      1. 监测和控制：洁净生产BMS/EMS控制系统能够实时监测和控制生产场所的环境参数，包括温度、湿度、空气质量、气流速度等。系统可以通过传感器网络收集数据，并通过中央控制台实现集中监控和管理。通过对环境参数的准确监测和智能化的控制，可以提高生产过程的稳定性和一致性，进而提升产品质量。
      <br>
      2. 能源管理与优化：洁净生产BMS/EMS控制系统还可以实现对能源的监测、控制和优化。系统可以实时采集和分析能源消耗数据，通过设置节能策略和自动化控制，降低能源浪费，提高能源利用效率。同时，系统还可以提供能源消耗的报告和分析，帮助企业了解能源消耗情况，并制定有效的节能措施。
      <br>
      3. 工艺优化与自动化：洁净生产BMS/EMS控制系统可以与生产设备和工艺自动化系统集成，实现工艺优化和自动化控制。通过对生产设备的监测和控制，系统可以调整生产参数，优化生产过程，提高生产效率和产能。此外，系统还可以实现生产流程的自动化控制，减少人为操作的干预，降低误操作和人为污染的风险。
      <br>
      4. 数据管理与报告：洁净生产BMS/EMS控制系统具备数据管理和报告功能。它可以收集、存储和分析洁净生产相关的数据，包括环境参数、能源消耗、生产指标等。系统可以生成报告和趋势分析，帮助企业了解生产情况、能源消耗和环境表现，并支持决策制定和绩效评估。
      <br>
      5. 合规与认证支持：洁净生产BMS/EMS控制系统可以支持企业的合规与认证工作。它可以记录和跟踪企业的环境指标和能源消耗指标，帮助企业满足环境法规和标准的要求。系统还可以生成相关的报告和证明材料，支持企业的环境认证和审核工作。
      <br>
      通过实施洁净生产BMS/EMS控制系统解决方案，企业可以实现洁净生产过程的智能化和可持续发展，减少环境污染和资源浪费，提高企业的竞争力和可持续发展能力。在选择和实施解决方案时，企业需要根据自身的需求和情况进行定制和配置，并与专业的解决方案提供商合作。
    </p>
    <h2>解决案例</h2>
    <div>
      <ul class="imgList">
        <li v-for="item in imgList" :key="item.id">
          <img alt="" :src="item.src">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  mounted() {

  },
  data() {
    return {
      imgList: [
        {
          id: 1,
          src: require("../../../assets/image/jiejing1.png")
        },{
          id: 2,
          src: require("../../../assets/image/jiejing2.png")
        },{
          id: 3,
          src: require("../../../assets/image/jiejing3.png")
        },{
          id: 4,
          src: require("../../../assets/image/jiejing4.png")
        },
      ]
    };
  },

  methods: {}


};
</script>
<style lang="less">
.solutionList-content {
  .imgList {
    display: flex;
    flex-direction: column;
    li {
      //flex-grow: 1;
      margin-bottom: 20px;
      img {
        width: 80%;

      }
    }
    li:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
