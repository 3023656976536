<template>
  <div class="solutionList">
    <div class="h">
<!--      <h1 align="center" class="animate__animated animate__bounceInRight">-->
<!--        SOLUTION LIST-->
<!--      </h1>-->
<!--      <h2 align="center" class="animate__animated animate__bounceInRight">-->

<!--      </h2>-->
    </div>
    <div class="solutionList-index">
      <h2 class="title">解决方案</h2>

      <ul class="solution-ul">
        <li class="solution-li" v-for="(item, index) in solutionList" :key="index">
          <h4 :class="{ active: item.id === solutionId }" @click="handleClick(item)">
            <span v-if="item.id === solutionId">&gt;</span>
            {{ item.name }}
            <span v-if="item.id === solutionId">&lt;</span>
          </h4>
        </li>
      </ul>
    </div>

    <niangjiu v-if="solutionId === 1"></niangjiu>
    <zhiyao v-if="solutionId === 2"></zhiyao>
    <xiangjin v-if="solutionId === 3"></xiangjin>
    <liangyou v-if="solutionId === 4"></liangyou>
    <guwu v-if="solutionId === 5"></guwu>
    <huanbao v-if="solutionId === 6"></huanbao>
    <jiejing v-if="solutionId === 7"></jiejing>
    <FMCS v-if="solutionId === 8"></FMCS>
  </div>
</template>

<script>
import niangjiu from "@/components/solution/components/niangjiu.vue";
import zhiyao from "@/components/solution/components/zhiyao.vue";
import xiangjin from "@/components/solution/components/xiangjin.vue";
import liangyou from "@/components/solution/components/liangyou.vue";
import guwu from "@/components/solution/components/guwu.vue";
import huanbao from "@/components/solution/components/huanbao.vue";
import jiejing from "@/components/solution/components/jiejing.vue";
import FMCS from "@/components/solution/components/FMCS.vue";
export default {
  components: {
    niangjiu,
    zhiyao,
    xiangjin,
    liangyou,
    guwu,
    huanbao,
    jiejing,
    FMCS,
  },
  data() {
    return {
      solutionList: [
        {
          name: "酿酒行业控制系统解决方案",
          id: 1
        }, {
          name: "制药行业控制系统解决方案",
          id: 2
        }, {
          name: "香精香料、调味品行业控制系统解决方案",
          id: 3
        }, {
          name: "粮油行业控制系统解决方案",
          id: 4
        }, {
          name: "谷物饲料行业控制系统解决方案",
          id: 5
        }, {
          name: "环保行业控制系统解决方案",
          id: 6
        }, {
          name: "洁净生产BMS/EMS控制系统解决方案",
          id: 7
        }, {
          name: "FMCS厂务管理控制系统解决方案",
          id: 8
        }
      ],
      solutionId: 1
    };
  },

  methods: {
    handleClick(item) {
      this.solutionId = item.id;
    }
  }


};
</script>
<style lang="less">
.solutionList {
  position: relative;
  text-align: center;
  padding: 0 240px;

  .h {
    width: 100%;
    position: absolute;
    top: -20%;
    left: 0px;

    h1 {
      color: #ffffff;
      font-size: 72px;
      font-family: Quantum;
      font-weight: 400;
    }

    h2 {
      color: #ffffff;
      font-size: 44px;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }
  }

  .solutionList-index {
    margin-top: 20px;

    .title {
      color: #000;
      font-size: 44px;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }

    .solution-ul {
      display: flex;
      flex-wrap: wrap;

      .solution-li {
        margin-top: 10px;
        width: 50%;

        h4 {
          display: inline-block;
          font-size: 18px;
          margin: 0 60px;
          padding: 10px 15px;
          border-radius: 10px;
          cursor: pointer
        }

        .active {
          color: #fff;
          background-color: #ff6e29;
        }
      }
    }
  }

  .solutionList-content {
    margin-top: 80px;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    padding: 20px 50px;
    background: url(../../assets/image/792.png) repeat;
    h2 {
      color: #ccc;
      font-size: 22px;
    }
    p {
      font-size: 18px;
      color: #fff;
      text-align: left;
      text-indent:2em
    }
  }
}

@media all and (max-width: 700px) {
  .typical-case {
    position: relative;

    .h {
      width: 100%;
      position: absolute;
      top: -370px;

      h1 {
        color: #ffffff;
        font-size: 100px;
        font-family: Quantum;
        font-weight: 400;
      }

      h2 {
        color: #ffffff;
        font-size: 54px;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
    }

    .subtitle {
      margin-top: 60px;
      font-size: 80px;
      font-family: Source Han Sans CN;
      font-weight: bold;
    }

    .system-list {
      .row-list-top {
        width: 1440px;
        margin: 0 auto;
        // top: -40px;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0 10px 20px #cccccc;

        .top-item {
          padding: 50px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .p1 {
            margin-top: 24px;
            margin-bottom: 0px;
            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 500;
          }

          .p2 {
            margin-top: 8px;
            font-size: 11px;
            font-family: Source Han Sans CN;
            font-weight: 400;
          }

          &:hover {
            color: #ffffff;
            // background: url("../assets/image/793.png");
            transition: all 0.5s ease-out;
          }
        }
      }

      .sys-list-item {
        width: 1240px;
        margin: 0 auto;
        // background-color: aqua;
        li {
          width: 100%;
          margin-top: 60px;
          display: flex;
          flex-wrap: wrap;

          .sys-box-l {
            width: 100%;
            padding: 15px;
            background: url("../../assets/image/路径 29041.png") no-repeat;
            background-position: 0 100%;

            img {
              width: 100%;
              height: 100%;
            }

            h3 {
              font-size: 48px;
              font-family: Source Han Sans CN;
              font-weight: bold;
            }

            .p1 {
              font-size: 24px;
              color: cadetblue;
              font-family: Source Han Sans CN;
              font-weight: 400;
            }
          }

          .sys-box-r {
            padding: 30px;
            width: 100%;

            h3 {
              font-size: 58px;
              font-family: Source Han Sans CN;
              font-weight: bold;
            }

            .p-one {
              text-indent: 1rem;
              margin-top: 16px;
              font-size: 48px;
              // font-family: Source Han Sans CN;
              // font-weight: 400;
            }

            .p-two {
              margin-top: 16px;

              img {
                width: 100px;
                height: 100px;
                margin-right: 20px;
              }

              span {
                font-size: 38px;
              }
            }

            .btn1 {
              margin-top: 20px;
              width: 500px;
              height: 100px;
              padding: 10px;
              background: rgba(255, 255, 255, 0.39);
              border-radius: 20px;
              border: 1px solid #e85500;
              color: #e85500;
              bottom: 15px;
              // transition: all 0.2s ease;
              cursor: pointer;
              font-size: 48px;

              &:active {
                background-color: #e85500;
                color: #ffffff;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    .process-flow {
      width: 1440px;
      margin: 0 auto;
      padding-bottom: 40px;

      // background-color: cadetblue;
      .pro-icon {
        text-align: center;

        p {
          margin: 0;
          font-size: 48px;
          font-family: Source Han Sans CN;
          font-weight: 800;
        }

        img {
          width: 206px;
          height: 198px;
        }
      }

      .pro-small {
        display: flex;
        align-items: center;

        img {
          line-height: 100%;
          width: 58px;
          height: 14px;
        }
      }
    }

    .swiper {
      width: 1440px;
      margin: 0 auto;
      border-radius: 20px;

      .swiper-wrapper {
        .swiper-slide {
          border-radius: 20px;
          position: relative;
          overflow: hidden;

          .item-mask {
            cursor: pointer;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.6);
            opacity: 0;
            color: #ffffff;
            text-align: center;
            transition: 0.5s opacity ease;

            &:hover {
              opacity: 1;
            }

            display: flex;
            align-items: flex-end;
            justify-content: center;

            div {
              // position: absolute;
              // top: 180px;
              // z-index: 999;
              // width: 100%;
              // height: 100%;
              background: url("../../assets/image/804.png") no-repeat;
              background-position: 100% 100%;
              background-size: 100% 100%;
              // border-radius: 20px;
              padding: 20px;
              margin: 0 auto;

              .h-p {
                margin: 0;
                font-size: 58px;
                font-family: Source Han Sans CN;
                font-weight: 500;
              }

              .t {
                margin-top: 25px;
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 400;
              }
            }
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}</style>
